import cn from 'classnames';
import { useParams } from 'react-router-dom';
import { t } from 'tools/i18n';

import {
  useDownloadKedoFileMutation,
  useGetInquiriesArchiveMutation,
} from 'services/1c/cApiService';

import { Button, Icon, Typography } from 'gazprom-ui-lib';

import DocumentView from 'containers/document-view';

import { SocialProgramRequestType } from 'types/socialProgram.types';

import s from './Content.module.scss';
import { FileType, renderSign } from './content.utils';

interface Props {
  socialRequestData?: SocialProgramRequestType;
}

const Content = (props: Props) => {
  const { socialRequestData } = props;
  const params = useParams<{ id: string }>();
  const [downloadFile] = useDownloadKedoFileMutation();
  const [downloadArchive] = useGetInquiriesArchiveMutation();

  const renderFile = (file: FileType) => {
    const { fileKey, fileName, signs } = file;

    const isSigned = !!signs?.length;

    return (
      <div key={fileKey} className={cn(s.download, { [s.signed]: isSigned })}>
        <div>
          <Icon name="imageFilled" className={s.icon} />
          <div className={s.fileDescription}>
            <Typography.Text weight="500" size="14" type={isSigned ? 'success' : 'primary'}>
              {fileName}
            </Typography.Text>
            {signs?.map(renderSign)}
          </div>
        </div>
        <div>
          {isSigned && <Icon name="okFilled" color="var(--color-success)" />}
          <button
            className={s.downloadButton}
            onClick={() =>
              downloadFile({
                //@ts-ignore
                fileKey: file.fileKeyWithStamp || file.fileKey,
                fileName: file.fileName,
              })
            }>
            <Icon name="download" color="var(--color-primary-6)" />
          </button>
        </div>
      </div>
    );
  };

  const handleDownloadArchive = () => {
    if (socialRequestData && params.id)
      downloadArchive({ id: params.id, fileName: `${socialRequestData.id}.zip` });
  };

  return (
    <div className={s.wrapper}>
      <div>
        <Typography.Title level={5}>{t('common_social_program')}</Typography.Title>
        <Typography.Text type="secondary" size="14">
          {socialRequestData?.category?.name}
        </Typography.Text>
        <Typography.Text size="14">{socialRequestData?.statement?.name}</Typography.Text>
      </div>
      <DocumentView.Employee employee={socialRequestData?.employee} />
      {socialRequestData?.filledAttributes?.map(renderAttributes)}
      {!!socialRequestData?.files?.length && (
        <div className={s.filesWrapper}>
          <Typography.Title level={5}>{t('common_attached_files')}</Typography.Title>
          {socialRequestData.files.map(renderFile)}
          <Button onClick={handleDownloadArchive} leftIcon="download" className={s.archiveButton}>
            {t('common_download_archive')}
          </Button>
        </div>
      )}
    </div>
  );
};
const renderAttributes = (attribute: {
  attributeId: string;
  attributeName: string;
  num: number;
  userText: string;
}) => {
  return (
    <div key={attribute.attributeId}>
      <Typography.Title level={5}>{attribute.attributeName}</Typography.Title>
      <Typography.Text type="secondary" size="14" className={s.pre}>
        {attribute.userText}
      </Typography.Text>
    </div>
  );
};

export default Content;
