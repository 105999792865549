import { createMediaUploadUrl1c } from 'utils/createMediaUrl.utils';

export const getUploadLink = (kedoDocumentId: string) => {
  return createMediaUploadUrl1c(`kedo-files/kedo-document?kedoDocumentId=${kedoDocumentId}`);
};

export const getMetaLink = () => {
  return createMediaUploadUrl1c(`kedo-files/meta`);
};

export const getDownloadLink = (kedoDocumentId: string) => {
  return createMediaUploadUrl1c(`kedo-files/download/kedo-document/${kedoDocumentId}`);
};

export const MAX_SIZE = 100;

export const getFileKey = (url?: string) => url?.replace('api/v1/media/download/', '') ?? '';
export const AVAILABLE_TYPES = ['application/pdf'];
