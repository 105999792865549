import { mapFeedBacks } from 'pages/thank-you/thank-you.utils';

export type TMappedFeedBacks = ReturnType<typeof mapFeedBacks>;
export type TMappedFeedBack = TMappedFeedBacks[number];

export type TStatusFeedback = 'APPROVED' | 'DECLINED' | 'PENDING';

export enum THANK_YOU_STATUS_ENUM {
  APPROVED = 'APPROVED',
  DECLINED = 'DECLINED',
  PENDING = 'PENDING',
}

export type TFeedback = {
  authorEmployee: {
    position: null | string;
    division: null | string;
    employer: null | string;
  };
  authorPerson: {
    id: string;
    firstName: string;
    lastName: null | string;
    patronymic: null | string;
  };
  category: string;
  createDate: string;
  id: string;
  recipientEmployee: {
    position: null | string;
    division: null | string;
    employer: null | string;
  };
  recipientPerson: {
    id: string;
    firstName: string;
    lastName: string;
    patronymic: string;
  };
  status: {
    state: string;
    title: string;
    status: THANK_YOU_STATUS_ENUM;
  };
  text: string;
};
